import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import queryString from 'query-string';
// import { useNavigate } from "react-router-dom";

import { ReactComponent as AOExclamation } from '../components/andopen-exclamation.svg';
import SessionPageFrame from '../components/SessionPageFrame.js';
import PanelHeader from '../components/PanelHeader.js';
import ApiCall from '../components/ApiCall.js';
import Message from '../components/Message.js';
import themeSettings from '../theme.js';

import constants from '../constants.js';

const theme = createTheme(themeSettings);

export default function AuthStart({ grant_type }) {
  const qs = queryString.parse(window.location.search);
  const qsemail = qs.email;
const qspassword = qs.password; // hack, remove

const qsclient_id = qs.client_id;
const qsstate = qs.state;
const qsredirect_uri = qs.redirect_uri;
// const callback = qs.callback || null;
const qsresponse_type = qs.response_type;

  const qstenant = qs.tenant;
  const message = qs.message || `Sign In`;
  const subtitle = qs.subtitle || (grant_type === 'password' ? `Enter your &Open subdomain and your email address and password which you use to log in` : `Sign in using your identity provider – SSO`);
  // const navigate = useNavigate();

  // const [loggedIn, setLoggedIn] = React.useState(false);
  const [fail, setFail] = React.useState(false);
  const [signInClicked, setSignInClicked] = React.useState(false);
  // const [signOutClicked, setSignOutClicked] = React.useState(false);

  const [grantTypes, setGrantTypes] = React.useState(null);
  const [otherGrantTypes, setOtherGrantTypes] = React.useState([]);
  // const otherGrantTypes = [];

  const [loading, setLoading] = React.useState(true);

  React.useEffect(LoadGrantTypes, []);

  React.useEffect(() => {
    let grants = [];
    if(grantTypes === null){
      return;
    }
    Object.keys(grantTypes).forEach(id => {
      if(grant_type !== id){
        grants.push(grantTypes[id])
      }
    })
    setOtherGrantTypes(grants);
  }, [grantTypes, grant_type])

  function LoadGrantTypes(){
    // const qs = queryString.parse(window.location.search);
    // const callback = qs.callback || qs.redirect_uri || null;
    // const action = qs.action || 'sign-in';

    ApiGetAuthGranttypes(types => {
      setGrantTypes(types)
      setLoading(false);
    })
  }

  function ProviderButton({ provider, tenant, password, email }){
    const disabled = signInClicked || !tenant;

    function handleClick(){
      setSignInClicked(true);
      setFail(false);
      if(provider.uses_redirect){
        window.location.href = `${provider.url}?tenant=${tenant}`;
      }

      const options = {
        url: provider.url,
        // headers: {
        //   'x-andopen-tenant': tenant
        // },
        method: provider.method || 'post',
        maxRedirects: 1,
        responseType: 'json',
        data: {
          client_id: qsclient_id,
          state: qsstate,
          redirect_uri: qsredirect_uri,
          response_type: qsresponse_type,
          password: password,
          username: email,
          tenant: tenant,
        },
        withCredentials: true
      };

      ApiCall(options, (e, r) => {
        setSignInClicked(false);
        if(r && r.type === 'redirect' && r.redirect?.url){
          window.location.replace(r.redirect.url);
        // }
        // if(r && r.message === 'login_ok'){
        //   setFail(false);
        //   // setLoggedIn(true);
        //   if(r?.redirect?.url){
        //     window.location.replace(r.redirect.url);
        //   }
        } else {
          setFail(true);
        }
      })
    };

    return(
      <Button
        onClick={() => handleClick()}
        fullWidth
        color="primary"
        variant="outlined"
        disabled={disabled}
        startIcon={
          disabled ? <img alt={`${provider.id} logo`} src={`/logos/${provider.id}.svg`} style={{ opacity: 0.2 }} />
                   : <img alt={`${provider.id} logo`} src={`/logos/${provider.id}.svg`} />
        }
        sx={{
          shadow: 'none',
          borderRadius: '25px',
          fontSize: '18px',
          // border: '3px solid',
          mt: 3, mb: 2,
        }}
      >
        {provider.label}
      </Button>
    );
  }

  // function handleLogout(cb){
  //   const options = {
  //     url: `/logout`,
  //     headers: {},
  //     method: 'post',
  //     maxRedirects: 1,
  //     responseType: 'json',
  //     withCredentials: true
  //   };
  //   ApiCall(options, (e, r) => {
  //     setFail(false);
  //     setLoggedIn(false);
  //     setSignInClicked(false);
  //     setSignOutClicked(false);
  //     cb && cb();
  //   });
  // }

  function ApiGetAuthGranttypes(cb){
    const options = {
      url: `auth/grant_types`,
      headers: {
        'x-api-key': constants.auth_apikey
      },
      method: 'get',
      maxRedirects: 1,
      responseType: 'json',
      withCredentials: true
    };

    ApiCall(options, (e, r) => {
      cb(r?.grant_types||null)
    })
  }

  return (
    <SessionPageFrame>
      { loading
        ? <Message text="Loading…" />
        : <> { grantTypes
            ? <SignInPanel
                message={ message }
                subtitle={ subtitle }
                qstenant={ qstenant }
                />
            : <ErrorPanel message="Login servers are not available at this time"/>
          } </>
      }
    </SessionPageFrame>
  );

  function ErrorPanel({ message }){
    const color1 = theme.palette.primary.main;
    const color2 = theme.palette.primary.light;
    // const colorhead = theme.palette.primary.dark;

    return (
      <Box style={{
        backgroundColor: color2,
        color: color1,
        padding: '20px',
        width: `${constants.panelWidth}px`
        }}>
        <AOExclamation fill={color1} style = {{ width: '55px', height: '55px', margin: 20 }} />
        <Typography component="h1" variant="h4" color={color1}>
          Error
        </Typography>
        <Typography component="h1" variant="subtitle1" style={{ lineHeight: 1.2, margin: 20, opacity: 0.8 }}  color={color1}>
          {message}
        </Typography>
      {/*
        <Button
          onClick={() => { navigate('/webhooks') }}
          fullWidth
          variant="text"
          sx={{
            mt: 3, mb: 2,
            color: color1
          }}>
          View Webhooks
        </Button>
      */}
      {/*
        <Button
          // onClick={() => handleLogout()}
          fullWidth
          variant="text"
          disabled={signOutClicked}
          sx={{
            mt: 3, mb: 2,
            color: color1
          }}>
          Sign Out
        </Button>
      */}
      </Box>
    )
  }

  function SignInPanel({ qstenant, message, subtitle }){
    const [tenant, setTenant] = React.useState(qstenant||null);
    const [email, setEmail] = React.useState(null);
    const [password, setPassword] = React.useState(null);

    const color1 = theme.palette.primary.light;
    const color2 = theme.palette.primary.main;
    const colorhead = theme.palette.primary.dark;
    const colorheadtext = theme.palette.primary.main;

    return (
      <PanelHeader
        ribbon={true}
        showLogo={true}
        message={message}
        subtitle={subtitle}
        color1={color1}
        color2={color2}
        colorhead={colorhead}
        colorheadtext={colorheadtext}
        >
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          style={{
            paddingLeft: '30px',
            paddingRight: '30px',
            paddingTop: '10px',
            paddingBottom: '20px',
            borderRadius: '4px'
          }}>
          { grantTypes[grant_type]?.fields?.includes('tenant')
            && <TextField
              margin="normal"
              required
              fullWidth
              label="Subdomain"
              autoFocus

              defaultValue={qstenant}
              id="tenant"
              name="tenant"
              autoComplete="tenant"
              placeholder="mycompanyname"
              disabled={signInClicked}
              onChange={e => setTenant(e.target.value)}

              InputProps={{
                endAdornment: <InputAdornment position="end">.andopen.co</InputAdornment>,
              }}
            />
          }
          { grantTypes[grant_type]?.fields?.includes('email')
            && <TextField
              margin="normal"
              required
              fullWidth
              label="Email Address"

              defaultValue={qsemail}
              id="email"
              name="email"
              autoComplete="email"
              disabled={signInClicked}

              onChange={e => setEmail(e.target.value)}
            />
          }
          { grantTypes[grant_type]?.fields?.includes('password')
            && <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              // defaultValue={qspassword} // hack, remove
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
              disabled={signInClicked}

              onChange={e => setPassword(e.target.value)}
            />
          }
          {/*<pre>{JSON.stringify(grantTypes[grant_type].providers, null, 2)}</pre>*/}
          { grantTypes[grant_type].providers.map(provider => <ProviderButton tenant={tenant||qstenant} key={provider.id} provider={provider} password={password||qspassword} email={email||qsemail} />)
          }
          { fail &&
              <Typography component="div" variant="subtitle1">
                <p>
                  <b>Your login attempt failed.</b><br/>
                  Check your credentials and try again.
                </p>
              </Typography>
            }

        </Box>
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          style={{
            paddingLeft: '30px',
            paddingRight: '30px',
            paddingBottom: '20px',
          }}>
          <Grid container direction="column">
            <Grid item xs>
              <Grid container direction="row">
                <Typography component="div" variant="body2">
                  Don't have an account? &nbsp;
                </Typography>
                <Link href={constants.sign_up_for_andopen} variant="body2" color={color2}>
                  Sign Up to &Open
                </Link>
              </Grid>
            </Grid>

            <Grid item xs>
              <Grid container direction="row">
                { otherGrantTypes.filter(o => o.type==='authorization_code')[0]?.providers.map(item => {
                  return (
                    <React.Fragment key={item.id}>
                      <Typography variant="body2" color={color2}>
                        <RouterLink to={constants.grant_urls.authorization_code}>
                          {item.label}
                        </RouterLink>
                      </Typography>
                    </React.Fragment>
                  )
                }) }
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </PanelHeader>
    )
  }
}