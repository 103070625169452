import * as React from 'react';

import './App.css';
import { Routes, Route } from "react-router-dom";

// Pages
import AuthStart from './pages/AuthStart.js';
import AuthCallbacksGoogle from './pages/AuthCallbacksGoogle.js';
// import GrantAuthorizationCode from './pages/GrantAuthorizationCode.js';
import Home from './pages/Home.js';
import constants from './constants.js';

import { createTheme } from '@mui/material/styles';
import themeSettings from './theme.js'
const theme = createTheme(themeSettings);

const App = () => {
  console.log(`&Open \nauthentication-ui v${process.env.REACT_APP_VERSION}@${process.env.REACT_APP_GIT}`);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home color={theme.palette.primary.light} />} />
        <Route path={constants.grant_urls.password} element={<AuthStart grant_type="password" />} />
        <Route path={constants.grant_urls.authorization_code} element={<AuthStart grant_type="authorization_code" />} />
        <Route path="/auth/callbacks/google" element={<AuthCallbacksGoogle />} />
      </Routes>
    </div>
  );
}

export default App;
