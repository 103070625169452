const theme = {
  // shadows: ['none'],
  typography: {
    fontFamily: 'Nitti Grotesk, Roboto, Helvetica Neue, Arial, sans-serif'
  },
  shape: {
    // borderRadius: '4px'
  },
  palette: {
    primary: {
      light: '#F2EBE0',
      main: '#161616',
      dark: '#ECE2CE'
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    lighty: {
      light: '#22566D',
      type: '#22566D',
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    custom: {
      light: '#E1EBF0',
      main: '#22566D',
      dark: '#22566D'
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#F7DECB',
      main: '#A65E3A',
      dark: '#A65E3A'
      // contrastText: '#ffcc00',
    },

    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
}
export default theme;
