const constants = {
  sign_up_for_andopen: 'https://signup.andopen.co/signup',
  grant_urls: {
    'password': '/auth/password',
    // 'google': '/auth/authorization_code',
    'authorization_code': '/auth/authorization_code'
  },
  panelWidth: 400,
  // sign_up_url: 'https://andopenformakers.paperform.co/',
  // handlers_base_url: process.env.REACT_APP_HANDLERS_BASE_URL,
  // handlers_apikey: process.env.REACT_APP_HANDLERS_APIKEY,
  auth_base_url: process.env.REACT_APP_AUTH_BASE_URL,
  auth_apikey: process.env.REACT_APP_AUTH_APIKEY,
}

export default constants;
