import * as React from 'react';
import Typography from '@mui/material/Typography';
// import { createTheme } from '@mui/material/styles';

import AndOpenLogo from '../components/AndOpenLogo.js';
import constants from '../constants.js';

// import themeSettings from '../theme.js';
// const theme = createTheme(themeSettings);

export default function PanelHeader({ showLogo, message, color1, color2, colorhead, colorheadtext, subtitle, ribbon, children }){

  return(
    <div
      style={{
        backgroundColor: color1,
        color: color2,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        width: `${constants.panelWidth}px`
        }}>
      <div
        style={{
          backgroundColor: colorhead,
          color: colorheadtext,
          paddingTop: '30px'
          }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            paddingLeft: '30px',
            paddingRight: '30px'
            }}>
          { showLogo && <AndOpenLogo
            version="logo"
            color={colorheadtext}
            style={{ marginTop: '20px', marginBottom: '20px', maxWidth: '150px', alignSelf: 'flex-start' }}
            /> }
          <Typography component="h1" variant="h4" align="left" style={{ marginBottom: 20 }} >
            { message || `Let’s get going` }
          </Typography>
          <Typography component="h1" variant="subtitle1" style={{ lineHeight: 1.2, marginBottom: 20, opacity: 0.8 }} align="left">
            { subtitle || `Enter your &Open developer account username and password to access the developer portal and manage API keys.` }
          </Typography>
        </div>
      </div>

      {/*ribbonney footer*/}
      { ribbon &&
        <div
          style={{
            backgroundColor: color1,
            borderTop: `25px solid ${colorhead}`,
            borderRight: `${constants.panelWidth}px solid transparent`
          }}>
        </div>
      }

      {children}
    </div>
  );
}