import constants from '../constants.js';
import axios from 'axios';

export default function ApiCall(options, cb){
  let axios_options = {
    ...options,
    url: `${options.url.includes('https://') ? '' : constants.auth_base_url}${options.url}`,
    headers: {
      ...options.headers||{},
    }
  };
  if(constants.auth_apikey){
    axios_options.headers['x-api-key'] = constants.auth_apikey
  }

  axios(axios_options)
  .then(response => {
    cb(null, response.data)
  })
  .catch(error => {
    cb(error.response?.data || error, null)
  })
}
